import {handleActions} from "redux-actions";
import UserSession from "../models/UserSession";

export const SessionReducer = handleActions({
    GET_USER_SUCCESS: (state, action: any): UserSession => {
        console.log(">>>>>debug:", action.payload.headers["x-user-session"]);
        return {
            email: action.payload.data.email,
            header: action.payload.headers["x-user-session"],
            name: action.payload.data.name,
            pic: action.payload.data.picture,
            role: action.payload.data.role,
        };
    },
    LOGOUT_SUCCESS: (state, action: any): UserSession | null => null,
    CLEAR_SESSION: (state, action: any): UserSession | null => null,
}, null);
